<script setup>
import { computed } from 'vue'

const props = defineProps({
  value: {
    type: Boolean,
    default: false,
  },
  texto : {
    type: String,
    default: '',
  }

})
console.log('props',props)
defineEmits(['upload', 'close'])

const opened = computed(() => props.value)
</script>

<template>
  <v-dialog
    v-model="opened"
    persistent
    max-width="400"
  >
    <v-card>
      <v-card-title class="text-h5">
        ¿Está seguro?
      </v-card-title>
      <v-card-text>
        {{ props.texto }}
        Al enviar los datos al servidor, estos serán procesados y almacenados en la base de datos de forma permanente.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          variant="text"
          @click="$emit('close')"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          variant="text"
          @click="$emit('upload')"
        >
          Enviar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
