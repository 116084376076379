import SyncService from '@/services/sync'


export default {
  namespaced: true,
  state: {
    pending: JSON.stringify([]),
    conductors: JSON.stringify([]),
    rutas: JSON.stringify([]),
    explotaciones: JSON.stringify([]),
    letraqs: JSON.stringify([]),
    centrorecogidas: JSON.stringify([]),
    explotacionesRuta: JSON.stringify([]),
    explotacionDatos:  JSON.stringify([]),
    explotacionTanques: JSON.stringify([]),
    incidencias: JSON.stringify([]),
    destinos: JSON.stringify([]),
    industrias: JSON.stringify([]),
    nifs: JSON.stringify([]),

    success: null,
    error: null,
  },
  getters: {
    pending: state => JSON.parse(state.pending),
    conductors: state => JSON.parse(state.conductors),
    rutas: state => JSON.parse(state.rutas),
    explotaciones: state => JSON.parse(state.explotaciones),
    letraqs: state => JSON.parse(state.letraqs),
    centrorecogidas: state => JSON.parse(state.centrorecogidas),
    explotacionesRuta: state =>  JSON.parse(state.explotacionesRuta),
    explotacionDatos:  state =>  JSON.parse(state.explotacionDatos),

    explotacionTanques: state =>JSON.parse(state.explotacionTanques),
    incidencias: state =>JSON.parse(state.incidencias),
    destinos: state =>JSON.parse(state.destinos),
    industrias: state =>JSON.parse(state.industrias),
    nifs: state =>JSON.parse(state.nifs),



    getRutaConductorUsuario2: (state) =>(id) =>  {
      const rutas =JSON.parse(state.rutas)
      console.log('1011 -- rutaConductorUsuario',rutas,id)

      return  rutas.find( item => item.Conductor.id === id)
                        // const configItem2 = config.find(item=> item.Ruta.id === collect.Ruta.id)

     }, 


     getRutaConductorUsuario : state => (id)=>{

      const rutas =JSON.parse(state.rutas)
      console.log('1011 -- rutaConductorUsuario',rutas)
      return rutas.filter((a) => {

         return a.Ruta.id === id
      })
    },


    getDestinos : state => {

      const destinos =JSON.parse(state.destinos)

      return destinos.filter((a) => {
        return a.id>1;
       // return a.tipo === 'Explotacion' || a.tipo === 'Cisterna'
        // return a.familia === 'Destino Rechazo'
      })
    },
    getMotivosExplotacion : state => {

      const incidencias =JSON.parse(state.incidencias)

      return incidencias.filter((a) => {

        return a.tipo === 'Explotacion'
      })
    },
    getMotivosCisterna : state => {

      const incidencias =JSON.parse(state.incidencias)

      return incidencias.filter((a) => {

        return a.tipo === 'Cisterna'
      })
    },
    getSilos : state => {

      const letraq =JSON.parse(state.letraqs)

      return letraq.filter((a) => {

         return a.tipo === 'Silo'
      })
    },
    getCisternas : state => {

      const letraq =JSON.parse(state.letraqs)

      return letraq.filter((a) => {

         return a.tipo === 'Cisterna'
      })
    }



  },
  mutations: {


    //const rutaExplotacionCo =computed(() => {return routes.value.filter((t) => t.Ruta.id===ruta.Ruta.id)})

    FILTERED_EXPLOTACION_TANQUES (state,explotacion) {

      const et =JSON.parse(state.explotaciones)
       const filter =et.filter((t) => {return t.id===explotacion})
       const filter2 =et.filter((t) => t.id===explotacion)

       console.log('FILTERED_EXPLOTACION_TANQUES',et,Array.isArray(et),typeof(et),filter,typeof(filter),filter2,typeof(filter2),Object.values(filter)[0]) 

        //SOLO TANQUES state.explotacionTanques =JSON.stringify(Object.values(filter)[0].tanques)
        //EXPLOTACION + TANQUES 
        state.explotacionTanques =JSON.stringify(Object.values(filter)[0])



    },
    
    SET_DATA(state, data) {
      for (const key in data) {
        if (Object.hasOwnProperty.call(state, key)) {
          state[key] = JSON.stringify(data[key])
        }
      }
    
    },
    SET_EXPLOTACIONES(state,data){
      state.explotacionesRuta = JSON.stringify(data)

    },
    SET_EXPLOTACION(state,data){
      state.explotacionDatos = JSON.stringify(data)

    },
    RESET_PENDING(state) {
      state.pending = JSON.stringify([])
    },

    RESET_RUTAS(state) {
      state.rutas = JSON.stringify([])
      console.log('0410 reset rutas',state,state.rutas)
      
    },
    

  },
  actions: {



     deleteData({commit,state }){
      commit('RESET_RUTAS')

      console.log('0410 SYNC delete rutas',state.value)



     },

   /*  FILTERED_EXPLOTACION_TANQUES({commit}, explotacion) {
      commit('FILTERED_EXPLOTACION_TANQUES', explotacion)
    }, */
    async upload({ commit, state }) {
      try {
        commit('SET_LOADING', true, { root: true })

        console.log('upload',commit,state)
       // await SyncService.upload(JSON.parse(state.pending))
       await SyncService.upload(JSON.parse(state.parteDescarga))
        commit('RESET_PENDING')
        //commit('RESET_ENVIO')
        
        commit('SET_SUCCESS', true, { root: true })
      } catch (error) {
        //commit('SET_ERROR', error, { root: true })
        throw error

      } finally {
        commit('SET_LOADING', false, { root: true })
      }
    },
    async download({ commit }) {
      try {
        commit('SET_LOADING', true, { root: true })
        const data = await SyncService.download()
        commit('SET_DATA', data)
        commit('SET_SUCCESS', true, { root: true })
      } catch (error) {
        throw error
      } finally {
        commit('SET_LOADING', false, { root: true })
      }
    },
    async createCollect({ commit, rootState, state }, collect) {


      console.log('collect-sync',collect)
      try {
        commit('SET_LOADING', true, { root: true })
      //  if (rootState.online) {
          //await SyncService.upload(collect)
       // } else {
          const pending = JSON.parse(state.pending)
          console.log('collect',pending,typeof(pending))
          pending.push(collect)
        
          commit('SET_DATA', { pending })
        //}
        commit('SET_SUCCESS', true, { root: true })
      } catch (error) {
        throw error;
        //commit('SET_ERROR', error, { root: true })
      } finally {
        commit('SET_LOADING', false, { root: true })
      }
    }
  }
}
