import AuthService from '@/services/auth'
import { object } from 'yup'

export default {
  namespaced: true,
  state: {
    user: null,
    token: null,
    error: null,
    conductor: null,
  },
  
  getters: {

    user: state => state.user,
    token: state => state.token,
    error: state => state.error,
    conductor: state => state.conductor,
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_CONDUCTOR(state, conductor) {
      state.conductor = conductor
    },
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_ERROR(state, error) {
      state.error = error
    }
  },
  
  actions: {
    async login({ commit }, { email, password }) {
      commit('SET_ERROR', null)
      try {
        commit('SET_LOADING', true, { root: true })
        const { data } = await AuthService.login({ email, password })

        


        commit('SET_USER', data.user)
        commit('SET_TOKEN', data.token)
        commit('SET_CONDUCTOR',data.user.conductor.id)
      } catch (error) {
      /*  141023 if ( ! error.response) {
         // throw new Error(error.message)
         commit('SET_ERROR', error)
         console.log('error 1',error)
        
         throw new Error(error)
        } */
        console.log('error 2',typeof(error),error,error.response.data,typeof(error.response.data.error))
        
        if (typeof(error.response.data.error)==='object') {
          const errors = [];
          for (const key in error.response.data.error) {
            errors.push(error.response.data.error[key][0]);
          }
             //throw new Error(errors.join(', '))
             console.log('register error',errors)
             ///commit('SET_ERROR', {error})

             throw new Error(errors)
             console.log('register error',error,errors, new Error(errors))


        }else {

          commit('SET_ERROR', error.response.data.error)

          console.log('register else',error.response.data.error)

          throw new Error(error.response.data.error)
        }
        //throw new Error(error.response.data.error)

      } finally {
        commit('SET_LOADING', false, { root: true })
      }
    },
    async register({ commit }, { name, email, password }) {
      commit('SET_ERROR', null)
      try {
        commit('SET_LOADING', true, { root: true })
        const { data } = await AuthService.register({ name, email, password, role_id: 4 })
            
        commit('SET_USER', data.user)
        commit('SET_TOKEN', data.token)
      } catch (error) {
        if ( ! error.response) {
         // throw new Error(error.message)
         throw error
        }

        if (error.response.data.error) {
          const errors = [];
          for (const key in error.response.data.error) {
            errors.push(error.response.data.error[key][0]);
          }
          //throw new Error(errors.join(', '))
          console.log('register error',error)
          ///commit('SET_ERROR', {error})

          throw new Error(error)
        }
      } finally {
        commit('SET_LOADING', false, { root: true })
      }
    },
    async logout({ commit, state }) {
      try {
        commit('SET_LOADING', true, { root: true })
        await AuthService.logout(state.token)
      } finally {
        commit('SET_USER', null)
        commit('SET_CONDUCTOR', null)
        
        commit('SET_TOKEN', null)
        commit('SET_LOADING', false, { root: true })

      }
    },
  }
}
