import axios from 'axios'
import store from '@/store'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 90000,
  headers: {
    'Content-Type': 'application/json',
  }
})
console.log('2009',instance);

instance.interceptors.request.use(
  config => {
    const token = store.getters['auth/token']
    const conductor = store.getters['auth/conductor']

    if (conductor){     
      config.headers['Conductor'] = conductor
    }

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
  
    return config
  }
)

export default instance
