import api from '@/services/api';

class Auth {
  async login(user) {
    try {
      return api.post('login', user)

      
    } catch (error) {
      console.log('error login ',error)      
    }
    
  }

  async register(user) {
    return api.post('register', user)
  }

  async logout() {
    return api.post('logout')
  }
}

export default new Auth();
