<script setup>
import { ref } from 'vue'
import AppAuthLayout from './layouts/AppAuthLayout.vue'
import AppGecotrailLayout from './layouts/AppGecotrailLayout.vue'
import { useStore } from 'vuex'
import OverlayLoader from '@/components/AppOverlayLoader.vue'

const store = useStore()
const restored = ref(false)

store.restored.then(() => {
  restored.value = true
})

window.addEventListener('online', () => {
  store.commit('SET_ONLINE', true)
})

window.addEventListener('offline', () => {
  store.commit('SET_ONLINE', false)
})
</script>

<template>
  <v-app v-if="restored">
    <overlay-loader />
    <component
      :key="$store.state.auth.user ? 'gecotrail' : 'auth'"
      :is="$store.state.auth.user ? AppGecotrailLayout : AppAuthLayout" />
  </v-app>
</template>

<style lang="stylus">

</style>
