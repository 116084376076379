import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import apiInterceptors from '@/services/apiInterceptors'

apiInterceptors(store);

createApp(App)
  .use(vuetify)
  .use(store)
  .use(router)
  .mount('#app')
