<script setup>
import { provide, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import useSnackbar from '@/composables/useSnackbar'
import NetworkStatus from '@/components/AppNetworkStatus.vue'
import SnackNotifier from '@/components/AppSnackNotifier.vue'
import AppDrawer from '@/components/Navigations/AppDrawer.vue'

const store = useStore()
const router = useRouter()

const { createSuccessSnackbar, createErrorSnackbar } = useSnackbar()
provide('createSuccessSnackbar', createSuccessSnackbar)
provide('createErrorSnackbar', createErrorSnackbar)

const appName = process.env.VUE_APP_NAME
const drawer = ref(false)

/* function(v){
  return drawer.value = v;
} */

watch(drawer, v => drawer.value = v)
</script>

<template>
  <v-app>
    <v-layout>
      <v-app-bar color="primary">
        <v-app-bar-nav-icon
          variant="text"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <v-toolbar-title>{{ appName }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <network-status></network-status>
      </v-app-bar>

      <app-drawer
        v-model="drawer"
        @close="drawer = false"
      ></app-drawer>

      <v-main>
        <v-layout>
          <v-container fluid fill-height>
            <router-view />
          </v-container>
        </v-layout>

      </v-main>

      <snack-notifier />
    </v-layout>
  </v-app>
</template>
