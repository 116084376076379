import store from '@/store'
import {  ref } from "vue";


export default {
  namespaced: true,
  state: {
  
  },
  getters: {

  
    resumenLitrosPorVehiculo: (state, getters) => {

      // uno partes 
      // hay cisterna ? numero de depositos
      // hay remolque ? numero de depositos 
      // por cisterna y deposito calculo litros y tipo leche 
      // por remolque y deposito calculo litros y tipo leche 
      /*  cisterna : [
                    { deposito: 01 , litros: 1999 , tipoLeche: vaca},
                    { deposito: 01 , litros: 1999 , tipoLeche: vaca},
                  ] */


      const parteRecogida =store.getters['recogida/getParteRecogida']
      const parteOtraRecogida =store.getters['recogida/getPartesOtraRecogida']
      const parteDescarga =store.getters['recogida/getParteDescarga']

     

      const rutaActual =  store.getters['recogida/getRutaActual']
      console.log('resumenLitrosPorDeposito', 
      parteRecogida,parteOtraRecogida, parteDescarga,'ruta--->',rutaActual)

     if (rutaActual.length === 0 ){return }

      
      
// SOLO PARTES SIN RUTA 
      const recogida = parteRecogida.map((rec)=> rec.Recogida)
      const otraRecogida = parteOtraRecogida.map((rec)=> rec.OtraRecogida)
      const descarga = parteDescarga.map((rec)=> rec.Descarga)

      const TotalLitrosRecogida = recogida.reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)
      const TotalLitrosOtraRecogida = otraRecogida.reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)
      const TotalLitrosDescarga = descarga.reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)

      
      const TotalLitrosCisternaRecogida = recogida.filter(item => item.cisternaRemolque === 'Cisterna').reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)
      const TotalLitrosCisternaOtraRecogida = otraRecogida.filter(item => item.cisternaRemolque === 'Cisterna').reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)
      const TotalLitrosCisternaDescarga = descarga.filter(item => item.cisternaRemolque === 'Cisterna').reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)



      const TotalLitrosRemolqueRecogida = recogida.filter(item => item.cisternaRemolque === 'Remolque').reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)
      const TotalLitrosRemolqueOtraRecogida = otraRecogida.filter(item => item.cisternaRemolque === 'Remolque').reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)
      const TotalLitrosRemolqueDescarga = descarga.filter(item => item.cisternaRemolque === 'Remolque').reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)
      
      

      const partes =  recogida.concat(otraRecogida);


      console.log('TotalLitrosVehiculo',partes)

      const DetalleLitrosCargadosCisterna = partes.filter(item => item.cisternaRemolque === 'Cisterna')
      const DetalleLitrosCargadosRemolque = partes.filter(item => item.cisternaRemolque === 'Remolque')
      
      const DetalleLitrosDescargadosCisterna = descarga.filter(item => item.cisternaRemolque === 'Cisterna')
      const DetalleLitrosDescargadosRemolque = descarga.filter(item => item.cisternaRemolque === 'Remolque')


      const TotalLitrosCargadosCisterna = DetalleLitrosCargadosCisterna.reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)
      const TotalLitrosCargadosRemolque = DetalleLitrosCargadosRemolque.reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)
      
      const TotalLitrosCargadosVehiculo = +TotalLitrosCargadosCisterna +TotalLitrosCargadosRemolque

      const TotalLitrosDescargadosCisterna = DetalleLitrosDescargadosCisterna.reduce((accumulator,currentValue) =>   +accumulator + +currentValue.litros,0,)
      const TotalLitrosDescargadosRemolque = DetalleLitrosDescargadosRemolque.reduce((accumulator,currentValue) =>   +accumulator + +currentValue.litros,0,)
      
      const TotalLitrosDescargadosVehiculo = +TotalLitrosDescargadosCisterna +TotalLitrosDescargadosRemolque


      const TotalLitrosCisterna = +TotalLitrosCargadosCisterna - +TotalLitrosDescargadosCisterna
      const TotalLitrosRemolque = +TotalLitrosCargadosRemolque - +TotalLitrosDescargadosRemolque

      const TotalLitrosVehiculo = +TotalLitrosCisterna + +TotalLitrosRemolque

      console.log('TotalLitrosVehiculo',DetalleLitrosCargadosCisterna,DetalleLitrosCargadosRemolque,
      DetalleLitrosDescargadosCisterna,DetalleLitrosDescargadosRemolque,
      'tottales',
      TotalLitrosCargadosCisterna,TotalLitrosCargadosRemolque,
      '----------------',
      TotalLitrosDescargadosVehiculo,
      '===================',
      TotalLitrosCargadosVehiculo,
      )


      console.log('partes cargados cisterna remoleque',DetalleLitrosCargadosCisterna,DetalleLitrosCargadosRemolque)
      console.log('partes descargados cisterna remoleque',DetalleLitrosDescargadosCisterna,DetalleLitrosDescargadosRemolque)

  
      console.log('partes total cargados en cisterna remoleque',TotalLitrosCargadosCisterna,TotalLitrosCargadosRemolque)
      console.log('partes total cargados: ',TotalLitrosCargadosVehiculo)
      
      console.log('partes total Des-cargados en cisterna remoleque',TotalLitrosDescargadosCisterna,TotalLitrosDescargadosRemolque)
      console.log('partes total descargados: ',TotalLitrosDescargadosVehiculo)
      

      console.log('partes total cisterna , deposito , vehiculo  : ',TotalLitrosCisterna,TotalLitrosRemolque,TotalLitrosVehiculo)

      /*************************************
      *TOTALES POR TIPO LECHE 
      ************************************
      */



      const TotalLitrosCargadosCisternaVaca = DetalleLitrosCargadosCisterna.filter(item => item.tipoLeche === 'Vaca').reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)
      const TotalLitrosCargadosCisternaOveja = DetalleLitrosCargadosCisterna.filter(item => item.tipoLeche === 'Oveja').reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)
      const TotalLitrosCargadosCisternaCabra = DetalleLitrosCargadosCisterna.filter(item => item.tipoLeche === 'Cabra').reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)

      const TotalLitrosDescargadosCisternaVaca = DetalleLitrosDescargadosCisterna.filter(item => item.tipoLeche === 'Vaca').reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)
      const TotalLitrosDescargadosCisternaOveja = DetalleLitrosDescargadosCisterna.filter(item => item.tipoLeche === 'Oveja').reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)
      const TotalLitrosDescargadosCisternaCabra = DetalleLitrosDescargadosCisterna.filter(item => item.tipoLeche === 'Cabra').reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)

      const TotalLitrosCisternaVaca = +TotalLitrosCargadosCisternaVaca - +TotalLitrosDescargadosCisternaVaca
      const TotalLitrosCisternaOveja = +TotalLitrosCargadosCisternaOveja - +TotalLitrosDescargadosCisternaOveja
      const TotalLitrosCisternaCabra = +TotalLitrosCargadosCisternaCabra - +TotalLitrosDescargadosCisternaCabra



      const TotalLitrosCargadosRemolqueVaca = DetalleLitrosCargadosRemolque.filter(item => item.tipoLeche === 'Vaca').reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)
      const TotalLitrosCargadosRemolqueOveja = DetalleLitrosCargadosRemolque.filter(item => item.tipoLeche === 'Oveja').reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)
      const TotalLitrosCargadosRemolqueCabra = DetalleLitrosCargadosRemolque.filter(item => item.tipoLeche === 'Cabra').reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)

      const TotalLitrosDescargadosRemolqueVaca = DetalleLitrosDescargadosRemolque.filter(item => item.tipoLeche === 'Vaca').reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)
      const TotalLitrosDescargadosRemolqueOveja = DetalleLitrosDescargadosRemolque.filter(item => item.tipoLeche === 'Oveja').reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)
      const TotalLitrosDescargadosRemolqueCabra = DetalleLitrosDescargadosRemolque.filter(item => item.tipoLeche === 'Cabra').reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)

      const TotalLitrosRemolqueVaca = +TotalLitrosCargadosRemolqueVaca - +TotalLitrosDescargadosRemolqueVaca
      const TotalLitrosRemolqueOveja = +TotalLitrosCargadosRemolqueOveja - +TotalLitrosDescargadosRemolqueOveja
      const TotalLitrosRemolqueCabra = +TotalLitrosCargadosRemolqueCabra - +TotalLitrosDescargadosRemolqueCabra




      const DetalleLitrosCargadosVaca = partes.filter(item => item.tipoLeche === 'Vaca')
      const DetalleLitrosCargadosOveja = partes.filter(item => item.tipoLeche === 'Oveja')
      const DetalleLitrosCargadosCabra = partes.filter(item => item.tipoLeche === 'Cabra')

      const DetalleLitrosDescargadosVaca = descarga.filter(item => item.tipoLeche === 'Vaca')
      const DetalleLitrosDescargadosOveja = descarga.filter(item => item.tipoLeche === 'Oveja')
      const DetalleLitrosDescargadosCabra = descarga.filter(item => item.tipoLeche === 'Cabra')




      const totalLitrosVacaCargados = DetalleLitrosCargadosVaca.reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)
      const totalLitrosOvejaCargados = DetalleLitrosCargadosOveja.reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)
      const totalLitrosCabraCargados = DetalleLitrosCargadosCabra.reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)

      const totalLitrosVacaDescargados = DetalleLitrosDescargadosVaca.reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)
      const totalLitrosOvejaDescargados = DetalleLitrosDescargadosOveja.reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)
      const totalLitrosCabraDescargados = DetalleLitrosDescargadosCabra.reduce((accumulator,currentValue) => +accumulator + +currentValue.litros,0,)


      console.log('partes cargados x tipo Leche ',DetalleLitrosCargadosVaca,DetalleLitrosCargadosOveja,DetalleLitrosCargadosCabra)
      console.log('partes partesdos x tipo Leche ',DetalleLitrosDescargadosVaca,DetalleLitrosDescargadosOveja,DetalleLitrosDescargadosCabra)
      
      console.log('partes cargados x TOTAL tipo Leche ',totalLitrosVacaCargados,totalLitrosOvejaCargados,totalLitrosCabraCargados)
      console.log('partes descargados x TOTAL tipo Leche ',totalLitrosVacaDescargados,totalLitrosOvejaDescargados,totalLitrosCabraDescargados)
      console.log('partes 2407',rutaActual.Cisterna.n_depositos)


      /*************************************
      *TOTALES POR deposito  
      ************************************
      */

      let listrosXDepositoVehiculo = []
      let litrosXDepositoRemolque = []
      let litrosXDepositoCisterna = []
      let item = {}

            
        let n 
        if (rutaActual.Cisterna.n_depositos>0){



          for (var i = 1; i < +rutaActual.Cisterna.n_depositos+1; i++) {
            n += i;
            console.log('2407',rutaActual.Cisterna.n_depositos,i,
            recogida,
            )

            let leche 
            let litros_recogidos= recogida.filter( item => item.cisternaRemolque === 'Cisterna' && item.deposito === i).reduce((accumulator, currentValue) => +accumulator + +currentValue.litros,0,)
            

            let litros_otras_recogidas=  otraRecogida.filter( item => item.cisternaRemolque === 'Cisterna' && item.deposito === i).reduce((accumulator, currentValue) => +accumulator + +currentValue.litros,0,)

            let litros_descargas=  descarga.filter( item => item.cisternaRemolque === 'Cisterna' && item.deposito === i).reduce((accumulator, currentValue) => +accumulator + +currentValue.litros,0,)
            let total_litros = +litros_recogidos + +litros_otras_recogidas - +litros_descargas 

            if (total_litros > 0) {
            // leche=  recogida.map((rec)=> rec.tipoLeche ).findLast((e)=> e)
             leche = partes.filter( item => item.cisternaRemolque === 'Cisterna' && item.deposito === i).reduce((accumulator, currentValue) => currentValue.tipoLeche,'',)
            
          

            

            }else{
               leche = ""

            }

              
            item = {
                tipo: "Cisterna",            
                deposito: i,

                leche:  leche,
              
                litros_recogidas:  litros_recogidos,

                litros_otras_recogidas: litros_otras_recogidas,

                litros_descargas:  litros_descargas,
                total_litros : total_litros,
                };

              //  if (total_litros !== 0) {
                  litrosXDepositoCisterna.push(item)
                  
              // }

               console.log('3008 cisterrna',leche,i,total_litros>0,item,litrosXDepositoCisterna)


          }
        }
      
        
        if (rutaActual.Remolque.n_depositos>0){

          for (var i = 1; i < +rutaActual.Remolque.n_depositos+1; i++) {
            n += i;

            let leche 
            let litros_recogidos= recogida.filter( item => item.cisternaRemolque === 'Remolque' && item.deposito === i).reduce((accumulator, currentValue) => +accumulator + +currentValue.litros,0,)
            

            let litros_otras_recogidas=  otraRecogida.filter( item => item.cisternaRemolque === 'Remolque' && item.deposito === i).reduce((accumulator, currentValue) => +accumulator + +currentValue.litros,0,)

            let litros_descargas=  descarga.filter( item => item.cisternaRemolque === 'Remolque' && item.deposito === i).reduce((accumulator, currentValue) => +accumulator + +currentValue.litros,0,)
            let total_litros = +litros_recogidos + +litros_otras_recogidas - +litros_descargas 

            if (total_litros > 0) {
              // leche=  recogida.map((rec)=> rec.tipoLeche).findLast((e)=> e)
              leche = partes.filter( item => item.cisternaRemolque === 'Remolque' && item.deposito === i).reduce((accumulator, currentValue) => currentValue.tipoLeche,'',)


            }else{
               leche = ""

            }



              item = {
                tipo: "Remolque",            
                deposito: i,

                leche:  leche,
              
                litros_recogidas:  litros_recogidos,

                litros_otras_recogidas: litros_otras_recogidas,

                litros_descargas:  litros_descargas,
                total_litros : total_litros,
                };
               // if (total_litros !== 0)  {
                  litrosXDepositoRemolque.push(item)
               
   
              // }

               console.log('3008 rremolque',leche,i,total_litros>0,item,litrosXDepositoRemolque)

          }
        }
      

      console.log('partes listrosXDepositoVehiculo ',listrosXDepositoVehiculo)


      const TotalLitrosXTipoRecogida = {
     
       
      
    }         
    
    const TotalLitrosXTipoLeche = {

/* 
      "DetalleLitrosCargadosVaca" : DetalleLitrosCargadosVaca,
      "DetalleLitrosCargadosOveja" : DetalleLitrosCargadosOveja,
      "DetalleLitrosCargadosCabra" : DetalleLitrosCargadosCabra,
     
      "DetalleLitrosDescargadosVaca" : DetalleLitrosDescargadosVaca,
      "DetalleLitrosDescargadosVaca" : DetalleLitrosDescargadosVaca,
      "DetalleLitrosDescargadosCabra" : DetalleLitrosDescargadosCabra, */

      /* 'totalLitrosVacaCargados': totalLitrosVacaCargados,
      'totalLitrosOvejaCargados': totalLitrosOvejaCargados,
      'totalLitrosCabraCargados': totalLitrosCabraCargados,

      'totalLitrosVacaDescargados': totalLitrosVacaDescargados,
      'totalLitrosOvejaDescargados': totalLitrosOvejaDescargados,
      'totalLitrosCabraDescargados': totalLitrosCabraDescargados,
 */
      
      'TotalLitrosRemolqueVaca': TotalLitrosRemolqueVaca,
      'TotalLitrosRemolqueOveja': TotalLitrosRemolqueOveja,
      'TotalLitrosRemolqueCabra': TotalLitrosRemolqueCabra,




  }         


    
      const Cisterna  = {
        "TotalLitrosRecogida" : TotalLitrosCisternaRecogida,
        "TotalLitrosOtraRecogida" : TotalLitrosCisternaOtraRecogida,
        "TotalLitrosDescarga" : TotalLitrosCisternaDescarga,

        "DetalleLitrosCargados" : DetalleLitrosCargadosCisterna,
        "DetalleLitrosDescargados" : DetalleLitrosDescargadosCisterna,

        "TotalLitros" : TotalLitrosCisterna,

        "TotalLitrosVaca" : TotalLitrosCisternaVaca,
        "TotalLitrosOveja" : TotalLitrosCisternaOveja,
        "TotalLitrosCabra" : TotalLitrosCisternaCabra,

        "litrosXDeposito": litrosXDepositoCisterna,

        "TotalLitrosRecogida" : TotalLitrosCisternaRecogida,
        "TotalLitrosOtraRecogida" : TotalLitrosCisternaOtraRecogida,
        "TotalLitrosDescarga" : TotalLitrosCisternaDescarga,
        "NumeroDepositos" :rutaActual.Cisterna.n_depositos,

        
      }    
      
      const Remolque  = {
        "TotalLitrosRecogida" : TotalLitrosRemolqueRecogida,
        "TotalLitrosOtraRecogida" : TotalLitrosRemolqueOtraRecogida,
        "TotalLitrosDescarga" : TotalLitrosRemolqueDescarga,

        "DetalleLitrosCargados" : DetalleLitrosCargadosRemolque,
        "DetalleLitrosDescargados" : DetalleLitrosDescargadosRemolque,

        "TotalLitros" : TotalLitrosRemolque,

        "TotalLitrosVaca" : TotalLitrosRemolqueVaca,
        "TotalLitrosOveja" : TotalLitrosRemolqueOveja,
        "TotalLitrosCabra" : TotalLitrosRemolqueCabra,

        "litrosXDeposito": litrosXDepositoRemolque,

        "TotalLitrosRecogida" : TotalLitrosRemolqueRecogida,
        "TotalLitrosOtraRecogida" : TotalLitrosRemolqueOtraRecogida,
        "TotalLitrosDescarga" : TotalLitrosRemolqueDescarga,
        "NumeroDepositos" :rutaActual.Remolque.n_depositos,

        
      }    

      const Vehiculo  = {
        'TotalLitrosCargadosVehiculo': TotalLitrosCargadosVehiculo,
        'TotalLitrosDescargadosVehiculo': TotalLitrosDescargadosVehiculo,
        'TotalLitrosVehiculo': TotalLitrosVehiculo,

        'TotalLitrosRecogida' : TotalLitrosRecogida,
        'TotalLitrosOtraRecogida' : TotalLitrosOtraRecogida,
        'TotalLitrosDescarga' : TotalLitrosDescarga,
        

      }

      const resumenLitrosPorVehiculo = {
                  
                    'recogida': recogida,
                    'otraRecogida': otraRecogida,
                    'descarga': descarga,

                    'TotalLitrosXTipoRecogida' : TotalLitrosXTipoRecogida,
                    'TotalLitrosXTipoLeche' : TotalLitrosXTipoLeche,

                    'Cisterna' : Cisterna,
                    'Remolque' : Remolque,
                    'Vehiculo' : Vehiculo,

                    'listrosXDepositoVehiculo' : listrosXDepositoVehiculo,
                  
                   
                  
              
                  }
      console.log('resumenLitrosPorVehiculo 26',resumenLitrosPorVehiculo,'rutaActual',rutaActual)
         
                  return resumenLitrosPorVehiculo

                  

    },


    

    totalLitrosCisterna: (state, getters) => {

      const parteRecogida =store.getters['recogida/getParteRecogida']
      const parteOtraRecogida =store.getters['recogida/getPartesOtraRecogida']
      const parteDescarga =store.getters['recogida/getParteDescarga']



      const cisterna = parteRecogida.filter( item => item.Recogida.cisternaRemolque === 'Cisterna')

      const cisternaD1 = parteRecogida.filter( item => item.Recogida.cisternaRemolque === 'Cisterna' && item.Recogida.deposito === '01')
      const cisternaD2 = parteRecogida.filter( item => item.Recogida.cisternaRemolque === 'Cisterna'&& item.Recogida.deposito === '02')
      const cisternaD3 = parteRecogida.filter( item => item.Recogida.cisternaRemolque === 'Cisterna'&& item.Recogida.deposito === '03')
      const cisternaD4 = parteRecogida.filter( item => item.Recogida.cisternaRemolque === 'Cisterna'&& item.Recogida.deposito === '04')


      console.log('111',cisterna)

      console.log('111',cisternaD1,cisternaD2,cisternaD3,cisternaD4)

      const cisterna2 = parteOtraRecogida.filter( item => item.OtraRecogida.cisternaRemolque === 'Cisterna')
      const cisterna3 = parteDescarga.filter( item => item.Descarga.cisternaRemolque === 'Cisterna')


      const recogidas = cisterna.reduce((accumulator, currentValue) => +accumulator + +currentValue.Recogida.litros,0,);

      const depositosCisterna = cisterna.map((rec) => {
          const D = {litros: 0}
         
            switch (rec.Recogida.deposito) {
              case '01':
                Object.create(D)
                D.deposito =  rec.Recogida.deposito;
                D.leche =  rec.Recogida.tipoLeche;
                D.litros =   +D.litros + +rec.Recogida.litros
                break;
              case '02':
                Object.create(D)

                D.deposito =  rec.Recogida.deposito;
                D.leche =  rec.Recogida.tipoLeche;
                D.litros =   +D.litros + +rec.Recogida.litros
                break;
              case '03':
                Object.create(D)

                D.deposito =  rec.Recogida.deposito;
                D.leche =  rec.Recogida.tipoLeche;
                D.litros =   +D.litros + +rec.Recogida.litros
                // do something
                break;
              default:
                //const D1 = {litros : 0}
            }
          
        return D ; 
      });

   

        //console.log('111 recogidasD0',accumulator.Recogida.litros,currentValue.Recogida.litros)

        // +accumulator + +currentValue.Recogida.litros
                 
        
      
      
    //  );

      const recogidasD1 = cisternaD1.reduce((accumulator, currentValue) => +accumulator + +currentValue.Recogida.litros,0,);

      const recogidasD2 = cisternaD2.reduce((accumulator, currentValue) => +accumulator + +currentValue.Recogida.litros,0,);
      const recogidasD3 = cisternaD3.reduce((accumulator, currentValue) => +accumulator + +currentValue.Recogida.litros,0,);
      const recogidasD4 = cisternaD4.reduce((accumulator, currentValue) => +accumulator + +currentValue.Recogida.litros,0,);


        console.log('111 recogidasD1',depositosCisterna,recogidasD1,recogidasD1)



      const otrasRecogidas = cisterna2.reduce((accumulator, currentValue) => +accumulator + +currentValue.OtraRecogida.litros,0,);
       
      const descargas = cisterna3.reduce((accumulator, currentValue) => +accumulator + +currentValue.Descarga.litros,0,);

      
      const total = (+recogidas + +otrasRecogidas)- +descargas 





      let vaca1 = cisterna.filter( item => item.Recogida.tipoLeche === 'Vaca')
      let vaca2 = cisterna2.filter( item => item.OtraRecogida.tipoLeche === 'Vaca')
      let vaca3 = cisterna3.filter( item => item.Descarga.tipoLeche === 'Vaca')
             vaca1 = vaca1.reduce(
             (accumulator, currentValue) => +accumulator + +currentValue.Recogida.litros,
             0,);
             vaca2 = vaca2.reduce(
               (accumulator, currentValue) => +accumulator + +currentValue.OtraRecogida.litros,
               0,);
             vaca3 = vaca3.reduce(
                 (accumulator, currentValue) => +accumulator + +currentValue.Descarga.litros,
                 0,);
               
           const vaca = +vaca1 + +vaca2 - +vaca3



      let oveja1 = cisterna.filter( item => item.Recogida.tipoLeche === 'Oveja')
      let oveja2 = cisterna2.filter( item => item.OtraRecogida.tipoLeche === 'Oveja')
      let oveja3 = cisterna3.filter( item => item.Descarga.tipoLeche === 'Oveja')
         oveja1 = oveja1.reduce(
           (accumulator, currentValue) => +accumulator + +currentValue.Recogida.litros,
           0,);
           oveja2 = oveja2.reduce(
             (accumulator, currentValue) => +accumulator + +currentValue.OtraRecogida.litros,
             0,);
           oveja3 = oveja3.reduce(
               (accumulator, currentValue) => +accumulator + +currentValue.Descarga.litros,
               0,);

         const oveja = +oveja1 + +oveja2 - +oveja3



      let cabra1 = cisterna.filter( item => item.Recogida.tipoLeche === 'Cabra')
      let cabra2 = cisterna2.filter( item => item.OtraRecogida.tipoLeche === 'Cabra')
      let cabra3 = cisterna3.filter( item => item.Descarga.tipoLeche === 'Cabra')

         cabra1 =cabra1.reduce(
           (accumulator, currentValue) => +accumulator + +currentValue.Recogida.litros,
           0,);
         cabra2 =cabra2.reduce(
             (accumulator, currentValue) => +accumulator + +currentValue.OtraRecogida.litros,
             0,);
         cabra3 =cabra3.reduce(
               (accumulator, currentValue) => +accumulator + +currentValue.Descarga.litros,
               0,);

         const cabra = +cabra1 + +cabra2 - +cabra3




         const litrosCisterna = {
           'Recogidas': recogidas,
           'OtrasRecogidas' : otrasRecogidas,
           'Descargas': descargas,
           'recogidasD1': recogidasD1,
           'recogidasD2': recogidasD2,
           'recogidasD3': recogidasD3,
           'recogidasD4': recogidasD4, 
           'Vaca' : vaca,
           'Oveja' : oveja,
           'Cabra' : cabra , 
           'Total': total ,
         }
         console.log('litrosCisterna',litrosCisterna,vaca ,oveja,cabra,total)
         console.log('litrosCisterna',oveja,oveja1 ,oveja2,oveja3)
         console.log('litrosCisterna',vaca,vaca1 ,vaca2,vaca3)
         console.log('litrosCisterna',cabra,cabra1 ,cabra2,cabra3)
         console.log('litrosCisterna 111 recogidasD1',recogidasD1,recogidasD2 ,recogidasD3,recogidasD4)

      return litrosCisterna 
     },



     totalLitrosRemolque: (state, getters) => {

      const parteRecogida =store.getters['recogida/getParteRecogida']
      const parteOtraRecogida =store.getters['recogida/getPartesOtraRecogida']
      const parteDescarga =store.getters['recogida/getParteDescarga']

      const cisterna = parteRecogida.filter( item => item.Recogida.cisternaRemolque === 'Remolque')
      const cisterna2 = parteOtraRecogida.filter( item => item.OtraRecogida.cisternaRemolque === 'Remolque')
      const cisterna3 = parteDescarga.filter( item => item.Descarga.cisternaRemolque === 'Remolque')


           const recogidas = cisterna.reduce(
             (accumulator, currentValue) => +accumulator + +currentValue.Recogida.litros,
             0,);
           const otrasRecogidas = cisterna2.reduce(
                 (accumulator, currentValue) => +accumulator + +currentValue.OtraRecogida.litros,
                 0,);
             
           const descargas = cisterna3.reduce(
               (accumulator, currentValue) => +accumulator + +currentValue.Descarga.litros,
               0,);
               const total = (+recogidas + +otrasRecogidas)- +descargas 





      let vaca1 = cisterna.filter( item => item.Recogida.tipoLeche === 'Vaca')
      let vaca2 = cisterna2.filter( item => item.OtraRecogida.tipoLeche === 'Vaca')
      let vaca3 = cisterna3.filter( item => item.Descarga.tipoLeche === 'Vaca')
             vaca1 = vaca1.reduce(
             (accumulator, currentValue) => +accumulator + +currentValue.Recogida.litros,
             0,);
             vaca2 = vaca2.reduce(
               (accumulator, currentValue) => +accumulator + +currentValue.OtraRecogida.litros,
               0,);
             vaca3 = vaca3.reduce(
                 (accumulator, currentValue) => +accumulator + +currentValue.Descarga.litros,
                 0,);
               
           const vaca = +vaca1 + +vaca2 - +vaca3



      let oveja1 = cisterna.filter( item => item.Recogida.tipoLeche === 'Oveja')
      let oveja2 = cisterna2.filter( item => item.OtraRecogida.tipoLeche === 'Oveja')
      let oveja3 = cisterna3.filter( item => item.Descarga.tipoLeche === 'Oveja')
         oveja1 = oveja1.reduce(
           (accumulator, currentValue) => +accumulator + +currentValue.Recogida.litros,
           0,);
           oveja2 = oveja2.reduce(
             (accumulator, currentValue) => +accumulator + +currentValue.OtraRecogida.litros,
             0,);
           oveja3 = oveja3.reduce(
               (accumulator, currentValue) => +accumulator + +currentValue.Descarga.litros,
               0,);

         const oveja = +oveja1 + +oveja2 - +oveja3



      let cabra1 = cisterna.filter( item => item.Recogida.tipoLeche === 'Cabra')
      let cabra2 = cisterna2.filter( item => item.OtraRecogida.tipoLeche === 'Cabra')
      let cabra3 = cisterna3.filter( item => item.Descarga.tipoLeche === 'Cabra')

         cabra1 =cabra1.reduce(
           (accumulator, currentValue) => +accumulator + +currentValue.Recogida.litros,
           0,);
         cabra2 =cabra2.reduce(
             (accumulator, currentValue) => +accumulator + +currentValue.OtraRecogida.litros,
             0,);
         cabra3 =cabra3.reduce(
               (accumulator, currentValue) => +accumulator + +currentValue.Descarga.litros,
               0,);

         const cabra = +cabra1 + +cabra2 - +cabra3




         const litrosRemolque = {
           'Recogidas': recogidas,
           'OtrasRecogidas' : otrasRecogidas,
           'Descargas': descargas,
           'Vaca' : vaca,
           'Oveja' : oveja,
           'Cabra' : cabra , 
           'Total': total ,
         }
         console.log('litrosRemolque',parteRecogida,parteOtraRecogida ,parteDescarga)

         console.log('litrosRemolque',litrosRemolque,vaca ,oveja,cabra,total)
         
         console.log('litrosRemolque',oveja,oveja1 ,oveja2,oveja3)
         console.log('litrosRemolque',vaca,vaca1 ,vaca2,vaca3)
         console.log('litrosRemolque',cabra,cabra1 ,cabra2,cabra3)

      return litrosRemolque 
     },












    
  },
  mutations: {
    
  },
  actions: {
   
   
  }
}
