<script setup>
import { computed,ref, inject } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import UploadDataToServer from "@/components/Dialogs/AppUploadDataToServer.vue";
//import DeleteDataToStore from '@/components/Dialogs/AppDeleteDataToStore.vue'
import AppModal from "@/components/Dialogs/AppModal.vue";

const emit = defineEmits(["close"]);

const createSuccessSnackbar = inject("createSuccessSnackbar");
const createErrorSnackbar = inject("createErrorSnackbar");


const store = useStore();
const router = useRouter();


// ruta enviada  ruta marcada con fecha envio para no poder enviar 2 en un dia 
const Config = computed(
  () => store.getters["recogida/getConfig"] || []
);


const dialog = ref(false);
const dialogEliminarPartes = ref(false);

const dialogDownload = ref(false);

const openDialog = () => {
  dialog.value = true;
  emit("close");
};

const openDialogDownload = () => {
  dialogDownload.value = true;
  emit("close");
};

const openDialogEliminarPartes = () => {
  dialogEliminarPartes.value = true;
  console.log("dialogeliminarPartes", dialogEliminarPartes);
  emit("close");
};

function eliminarPartes() {
  /*  const elimnados = partesGuardados.value.filter((e) => e.Explotacion.id !== parteRecogida.Explotacion.id) */
  //router.go('0')

  store

    .dispatch("recogida/deletePartesRealizados")
    .then(() => {
      console.log("3010", eliminarPartes, router);

      store
        .dispatch("sync/deleteData")
        .then(() => {
          console.log("3010", eliminarPartes, router);

          // router.push({ name: 'resumen' })
          //router.go('0')
          createSuccessSnackbar(
            "TODOS LOS PARTES SYNC HAN SIDO ELIMINADOS correctamente"
          );
        })
        .finally(() => {
          // router.go('0')
        })

        .catch(() => createErrorSnackbar("Error al ELIMINAR PARTES SYNC"))
        .finally(() => {
          //router.go('0')
          (dialogEliminarPartes.value = false), emit("close");
        });

      // router.push({ name: 'resumen' })
      //router.go('0')
      createSuccessSnackbar(
        "TODOS LOS PARTES HAN SIDO ELIMINADOS correctamente"
      );
    })

    .catch(() => createErrorSnackbar("Error al ELIMINAR PARTES"))
    .finally(() => (dialogEliminarPartes.value = false), emit("close"));
  //delete parteRecogida.Recogida
}

const uploadData = () => {
  //store.dispatch('sync/upload')
  consoel.log("uploadData",Config);
  Config.fechaEnvio = new Date(); 
  console.log("uploadData",Config);
  return 

  store
    .dispatch("recogida/upload")
    .then(() => createSuccessSnackbar("Datos enviados correctamente"))
    .catch((error) => createErrorSnackbar(error))
    .finally(() => {

      dialog.value = false;
      emit("close");
    });
};

const downloadData = () => {
  store
    .dispatch("sync/download")
    .then(() => createSuccessSnackbar("Datos descargados correctamente"))
    .catch((error) => {
      createErrorSnackbar(error);
      console.log("error", error);
      logout();
    })
    .finally(() => {
      dialogDownload.value = false;
      emit("close");
    });
};

const logout = () => {
  store
    .dispatch("auth/logout")
  
    .catch(() => router.push({ name: "login" }))
    .finally(() => 
      {
        
      store
      .dispatch("recogida/setConfig",[])
      .catch(() => router.push({ name: "login" }))
      .finally(
        router.push({ name: "login" })
      );

      }
    
    );

    
};
</script>

<template>
  <v-navigation-drawer class="bg-grey-lighten-4" theme="dark">
    <v-list dense>
      <!--   <v-list-item
        prepend-icon="mdi-home"
        title="Inicio"
        @click="router.push({ name: 'home' })"
      /> -->
      <!--  <v-list-item
        prepend-icon="mdi-newspaper-plus"
        title="Configuración"
        @click="router.push({ name: 'config' })"
      /> -->
      <!--   <v-list-item
        :disabled="!$store.state.online"
        prepend-icon="mdi-cloud-upload"
        title="Enviar datos"
        @click="openDialog"
      />  -->

      <!--    <v-list-item
        prepend-icon="mdi-newspaper-plus"
        title="Nueva recolecta"
        @click="router.push({ name: 'collects-create' })"
      />  -->
      <v-list-item
        prepend-icon="mdi-routes"
        title="Rutas"
        @click="router.push({ name: 'routes' })"
      />

      <!--  <v-list-item
        prepend-icon="mdi-routes"
        title="Otra Recogida"
        @click="router.push({ name: 'otrarecogida' })"
      />
      <v-list-item
        prepend-icon="mdi-arrow-down-bold-box-outline"
        title="Descargas"
        @click="router.push({ name: 'descargas' })"
      /> -->

      <v-list-item
        prepend-icon="mdi-book-open-page-variant"
        title="Resumen"
        @click="router.push({ name: 'resumen' })"
      />

      <v-list-item
        :disabled="!$store.state.online"
        prepend-icon="mdi-cloud-download"
        title="Recibir datos"
        @click="openDialogDownload"
      />

      <v-list-item
        :disabled="!$store.state.online"
        prepend-icon="mdi-cloud-upload"
        title="Eliminar datos"
        @click="openDialogEliminarPartes"
      />
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <v-btn color="primary" text block @click="logout">
          Cerrar sesión
        </v-btn>
      </div>
    </template>

    <upload-data-to-server
      v-if="$store.state.online"
      v-model="dialog"
      @upload="uploadData"
      @close="dialog = false"
    ></upload-data-to-server>

    <app-modal
      v-if="$store.state.online"
      :texto="'TODOS LOS DATOS , RECOGIDOS DE TODAS LAS RUTAS SERAN ELIMINADOS DE  forma permanente.'"
      v-model="dialogEliminarPartes"
      @ejecutar="eliminarPartes"
      @close="dialogEliminarPartes = false"
    ></app-modal>

    <app-modal
      v-if="$store.state.online"
      :texto="'Descarga y actualización de datos del servidor .'"
      v-model="dialogDownload"
      @ejecutar="downloadData"
      @close="dialogDownload = false"
    ></app-modal>
  </v-navigation-drawer>
</template>
