import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Auth/LoginView.vue')
  },

  {
    path: '/config',
    name: 'config',
    component: () => import(/* webpackChunkName: "login" */ '../views/App/Config/IndexView.vue')
  },

  // register
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Auth/RegisterView.vue')
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/App/HomeView.vue')
  },
  {
    path: '/collects/create',
    name: 'collects-create',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "collects-create" */ '../views/App/Collects/CreateCollectView.vue')
  },
 
 
  
  {
    path: '/recogida/descargas2',
    name: 'descargas',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "collects-create" */ '../views/App/Recogida/Descargas2/DescaResumen.vue')
  },
  {
    path: '/recogida/resumen',
    name: 'resumen',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "collects-create" */ '../views/App/Recogida/Resumen/IndexView.vue')
  },
  {
    path: '/routes',
    name: 'routes',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "routes" */ '../views/App/Routes/ListRouteView.vue')
  },
  {
    path: '/explotaciones',
    name: 'explotaciones',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "routes" */ '../views/App/Explotaciones/ListExploView.vue')
  },
  {
    path: '/imprimir-resumen',
    name: 'Imprimir-resumen',
    component: () => import(/* webpackChunkName: "routes" */ '../views/App/Recogida/Resumen/ResumenImpreso.vue')
  },
  {
    path: '/explotacion',
    name: 'explotacion',
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "routes" */ '../views/App/Explotaciones/ExploView.vue')
  }

]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored;

  // If the user is logged in, redirect to home page
  if (store.getters['auth/user']) {
    if (to.name === 'login') {
      return next({ name: 'routes' });
    }
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['auth/user']) {
      return next({ name: 'login' });
    }
  }

  await next();
}
router.beforeEach(waitForStorageToBeReady);

export default router
