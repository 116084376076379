import api from '@/services/api';

class Sync {
  async upload(data) {
    console.log('upload',data )
    //return  
    return api.post('upload', data)
  }

  async download() {
    const endpoints = ['rutas', 'explotaciones', 'letraqs', 'centrorecogidas','incidencias','destinos','industrias','conductors','nifs']
    const promises = Promise.all(endpoints.map((endpoint) => {
      return api.get(endpoint)
    }));

    return promises.then((data) => {
      const result = {}
      data.forEach((response, index) => {
        result[endpoints[index]] = response.data.data
      })
      return result
    })
      .catch((error) => {
        if (error.response.status === 429) {
          throw new Error('Demasiadas peticiones, espere unos minutos y vuelva a intentarlo.')
        }
        throw error;
      })
  }
}

export default new Sync();
