<template>
  <v-btn icon v-if="$store.state.online">
    <v-icon color="white">mdi-wifi</v-icon>
    <v-tooltip activator="parent" location="bottom">Conectado</v-tooltip>
  </v-btn>

  <v-btn icon v-else>
    <v-icon color="white">mdi-wifi-off</v-icon>
    <v-tooltip activator="parent" location="bottom">Desconectado</v-tooltip>
  </v-btn>
</template>
