import SyncService from '@/services/sync'


export default {
    namespaced: true,
    state: {

         config: JSON.stringify([]),
         configOk : false,
         rutaActual: JSON.stringify([]),
         parteRecogida : JSON.stringify([]),
         parteDescarga : JSON.stringify([]),
         parteOtraRecogida : JSON.stringify([]),
         partesExplotacion: JSON.stringify([]),



    },

    getters: {

    
    
        getParteRecogida: state =>JSON.parse(state.parteRecogida),

        filterParteRecogida : (state) => {

          const rutaActual =JSON.parse(state.rutaActual)
          const parteRecogida =JSON.parse(state.parteRecogida)

          console.log('rutaActual', rutaActual,typeof(rutaActual),'parteRecogida',parteRecogida)

          return parteRecogida.find( item => item.Empresa.id === rutaActual.Empresa.id)
        },

        getConfig: state =>JSON.parse(state.config),
        getConfigOk: state =>JSON.parse(state.configOk),
        
        getRutaActual : state=>JSON.parse(state.rutaActual),
        filterConfigRutaActual : (state) => {

          const rutaActual =JSON.parse(state.rutaActual)
          if(rutaActual.length === 0){
            console.log('ruta',rutaActual)

            return
          }else{
            console.log('ruta else',rutaActual)

          }
          const config =JSON.parse(state.config)
          
          //if(config.length === 0) {

            const buscar =  config.find( item => item.Ruta.id === rutaActual.Ruta.id)

            console.log('filterConfigRutaActual 1209', buscar,
             rutaActual,typeof(rutaActual),config.length,typeof(config))
  
            return config.find( item => item.Ruta.id === rutaActual.Ruta.id)
            

         /*  }else{
            return rutaActual


          } */

       
        },
        getConfigId: (state) =>(id) =>  {
         return  JSON.parse(state.config.find( item => item.Ruta.id === id))
                           // const configItem2 = config.find(item=> item.Ruta.id === collect.Ruta.id)

        },

        search(state){
          return  id => JSON.parse(state.config.find( item => item.Ruta.id === id))
 
       

        }, 
          
         
         getConfigId2: state => id =>  {
          return  JSON.parse(state.config.find( item => item.Ruta.id === id))
                            // const configItem2 = config.find(item=> item.Ruta.id === collect.Ruta.id)
 
         },
        
        getTodoById: (state) => (id) => {
          console.log('1111111111',id)
          const rutaActual =JSON.parse(state.rutaActual)
          return rutaActual.find(item => item.Ruta.id === id)
        },

        getParteDescarga: state =>JSON.parse(state.parteDescarga),
        
        getPartesOtraRecogida: state =>JSON.parse(state.parteOtraRecogida),
 
        
        getPartesExplotacion : state => (explo)=>{

          const recogidas =JSON.parse(state.parteRecogida)
          console.log('124 -- getPartesExplotacion',recogidas, explo)
          return recogidas.filter((a) => {
    
             return a.Recogida.Explotacion.id === explo
          })
        },

        getPartesRecogidaExplotacion: state =>JSON.parse(state.parteDescarga),



      },
    
    mutations: {

      SET_PARTES_EXPLOTACION(state,partes){
        state.partesExplotacion = JSON.stringify(partes);

      },

        SET_CONFIG_OK(state,value){
          state.configOk = value;

        },
      
        SET_PARTE_DESCARGA(state,parte){
          state.parteDescarga =JSON.stringify(parte)

        },
        SET_PARTE_OTRA_RECOGIDA(state,parte){
          state.parteOtraRecogida = JSON.stringify(parte)
        },
        
        SET_PARTE_RECOGIDA(state,parte){
          state.parteRecogida = JSON.stringify(parte)
        },
        
        SET_CONFIG (state,config) {
            console.log('SET_CONFIG 1209',state,config)
            state.config = JSON.stringify(config)
            console.log('SET_CONFIG2222',state,config)

        },
        SET_RUTA_ACTUAL(state,ruta){
        console.log('SET_RUTA_ACTUAL',ruta)

          state.rutaActual = JSON.stringify(ruta)
          //{}
          //JSON.stringify(ruta)
          //console.log('SET_RUTA_ACTUAL',state.rutaActual,ruta)

        },

        SET_CONF(state, data) {
          console.log('SET_CONF',state,'data',data)
          for (const key in data) {
            if (Object.hasOwnProperty.call(state, key)) {
              state[key] = JSON.stringify(data[key])
             // console.log('sest__conf',state[key],data[key])
            }
          }
        
        },
        RESET_ENVIO(state) {
          //state.recogida.parteDescarga = JSON.stringify([])
          
          //state.parteOtraRecogida = JSON.stringify([])
          //state.parteDescarga = JSON.stringify([])
          //state.parteRecogida = JSON.stringify([])

          
          console.log('RESET_ENVIO',state,state.parteDescarga,state.parteOtraRecogida)
          //ojo igual solo partes???
        },
        RESET_SYNC(state,rootState,data) {
          console.log('RESET_SYNC',state,rootState,data);
          //rootState.sync.rutas = [];


        },

      
    },

    actions: {


      async setRutaActual({ commit, rootState, state }, ) {
        try {
          // traigo parte 
          const rutaActual = []

          commit('SET_RUTA_ACTUAL',  rutaActual )
          commit('SET_LOADING', true, { root: true })
          commit('SET_SUCCESS', true, { root: true })


          } catch (error) {
            console.log('error-DESCARGA',error)
            throw error
            console.log('error-recogida',error)
            //commit('SET_ERROR', error, { root: true })
          } finally {
            commit('SET_LOADING', false, { root: true })
          }
        




      },


      async upload({ commit, state }) {

       // commit('SET_ERROR', error, { root: true })
        try {
          commit('SET_LOADING', true, { root: true })
  
          console.log('sssstate',state.config);

          // En config añadir fecha de envio para testeo .. 
          // NO DEJAR  ENVIAR EL MISMO DIA ...
/* 
          const config = state.config 
          config.fechaEnvio = new Date(); 
          const rutaActual = state.rutaActual
          const configItem = config.filter(item=> item.Ruta.id !== ruta.Ruta.id) || []
          
          configItem.push(config)
          commit('SET_CONFIG',  configItem )



          console.log('ssstate', config ) */
          
         


          console.log('upload 1',JSON.parse(state.parteDescarga))
         // await SyncService.upload(JSON.parse(state.pending))
         //const {data} = 
         
         const envio = {
          "recogidas" : JSON.parse(state.parteRecogida),
          "descargas" : JSON.parse(state.parteDescarga),
          "config" : JSON.parse(state.config),
          "rutaActual": JSON.parse(state.rutaActual),
          "otrasRecogidas" : JSON.parse(state.parteOtraRecogida),

          }
          const {envio2} = {
            "recogidas" : JSON.parse(state.parteRecogida),
            "descargas" : JSON.parse(state.parteDescarga),
            "config" : JSON.parse(state.config),
            "rutaActual": JSON.parse(state.rutaActual),
            "otrasRecogidas" : JSON.parse(state.parteOtraRecogida),
  
            }
         console.log('uuuupload 2',envio,envio2)

         //await SyncService.upload(JSON.parse(state.parteRecogida))
         const {data} = await SyncService.upload(envio)
        // await SyncService.upload(envio)

         console.log('uuuupload 3',data)
         commit('RESET_ENVIO')

         //* commit('RESET_PENDING')
         //* commit('SET_SUCCESS', true, { root: true })
        } catch (error) {
          if ( ! error.response) {
            
            console.log('! error.respones ', error);
            throw new Error(error.message)
           
            //throw error
           }

           if (error.response.data.error) {
            const errors = [error.response.data.error];
          /*   for (const key in error.response.data.error) {
              errors.push(error.response.data.error[key][0]);
            } */


            //throw new Error(errors.join(', '))
            console.log('register error',errors)
            ///commit('SET_ERROR', {error})
  
            throw new Error(errors)
          }
        } finally {
          console.log('finaly  ');
          commit('SET_LOADING', false, { root: true })
        }
      },

      async deletePartesRealizados({ commit, rootState, state }) {
        try {
          // traigo parte 
          console.log('0410 deletePartesRealizados STATE : ',rootState);
          //const parteGuardado = JSON.parse(state.parteRecogida) 

          const parteRecogida =[];// JSON.parse(state.parteRecogida)
          const parteOtraRecogida = [];//JSON.parse(state.parteOtraRecogida)
          const parteDescargas = [];//;JSON.parse(state.parteDescarga)
          const rutaActual = [];
          const config = [];
          
           commit('SET_PARTE_RECOGIDA',  parteRecogida )
          commit('SET_PARTE_OTRA_RECOGIDA',  parteOtraRecogida )
          commit('SET_PARTE_DESCARGA',  parteDescargas )
         commit('SET_RUTA_ACTUAL',  rutaActual )
         commit('SET_CONFIG',  config )
 
     /*   commit('RESET_SYNC',rootState)  
          commit('sync/SET_ERROR', null) */

                  
          commit('SET_LOADING', true, { root: true })            
          commit('SET_SUCCESS', true, { root: true })

          console.log('0410 deletePartesRealizados STATE : ',state);





        } catch (error) {
          console.log('error-recogida',error)
          throw error
        } finally {
          console.log('0410 deletePartesRealizados FINALY');

          commit('SET_LOADING', false, { root: true })
        }
      }, 
          

      async deleteSoloPartesRealizados({ commit, rootState, state }) {
        try {
          // traigo parte 
     

          //const parteGuardado = JSON.parse(state.parteRecogida) 

          const parteRecogida =[];// JSON.parse(state.parteRecogida)
          const parteOtraRecogida = [];//JSON.parse(state.parteOtraRecogida)
          const parteDescargas = [];//;JSON.parse(state.parteDescarga)
          const rutaActual = [];
          const config = [];
              
          
        commit('SET_PARTE_RECOGIDA',  parteRecogida )
          commit('SET_PARTE_OTRA_RECOGIDA',  parteOtraRecogida )
          commit('SET_PARTE_DESCARGA',  parteDescargas ) 
          commit('SET_CONFIG',  config )
       // commit('SET_RUTA_ACTUAL',  rutaActual ) 
 
     /*   commit('RESET_SYNC',rootState)  
          commit('sync/SET_ERROR', null) */

                  
          commit('SET_LOADING', true, { root: true })            
          commit('SET_SUCCESS', true, { root: true })

          console.log('0410 deletePartesRealizados STATE : ',state);





        } catch (error) {
          console.log('error-recogida',error)
          throw error
        } finally {
          console.log('0410 deletePartesRealizados FINALY');

          commit('SET_LOADING', false, { root: true })
        }
      }, 
          


      async deleteParteRecogida({ commit, rootState, state }, parteEnviado) {
        try {
          // traigo parte 
          const parteGuardado = JSON.parse(state.parteRecogida)


          console.log('inicializo ',parteGuardado,typeof(parteGuardado),
          'parteEnviado', parteEnviado,
          )


          
        /*   const parteItem = parteGuardado.filter(item=> 
            item.Recogida.Explotacion.id !== parteEnviado.Recogida.Explotacion.id
            ) || [] */
            
            const parteItem = parteGuardado.filter(item=> 
              item.Recogida.uuid !== parteEnviado.Recogida.uuid            
            ) || []

          

          
          //parteItem.push(parteEnviado)

          commit('SET_PARTE_RECOGIDA',  parteItem )
          console.log('assigno parte guardado',parteItem,
          'parteGuardado',parteGuardado
          )

            commit('SET_LOADING', true, { root: true })
                        
            commit('SET_SUCCESS', true, { root: true })

          } catch (error) {
            console.log('error-recogida',error)
            throw error
            console.log('error-recogida',error)
            //commit('SET_ERROR', error, { root: true })
          } finally {
            commit('SET_LOADING', false, { root: true })
          }
      
      },

      async setParteRecogida({ commit, rootState, state }, parteEnviado) {
        try {
          // traigo parte 
          console.log('124 setParteRecogida',
          parteEnviado.Recogida
          ,parteEnviado.Recogida.uuid && parteEnviado.Recogida.uuid >0)

            if (parteEnviado.Recogida.uuid && parteEnviado.Recogida.uuid >0  ) {

              console.log('124 SI hay id', parteEnviado.Recogida.uuid)

            }else{
              parteEnviado.Recogida.uuid =   Math.floor(Math.random() * 1000);

              console.log('124 NO hay id', parteEnviado.Recogida.uuid, parteEnviado.Recogida)


            } 

          const partesRecogidaGuardados = JSON.parse(state.parteRecogida)
          console.log('124 setParteRecogida partesRecogidaGuardados',partesRecogidaGuardados)

          const parteItem = partesRecogidaGuardados.filter(item=> 
              item.Recogida.uuid !== parteEnviado.Recogida.uuid            
            ) || []

          //JSON.parse(state.parteRecogida)
          //partesRecogidaGuardados.filter(item=> item.Recogida.Explotacion.id !== parteEnviado.Recogida.Explotacion.id) || []
          console.log('124 setParteRecogida',parteItem,parteEnviado,partesRecogidaGuardados.filter(item=> 
            item.Recogida.uuid !== parteEnviado.Recogida.uuid            
          ))

          parteItem.push(parteEnviado)

          commit('SET_PARTE_RECOGIDA',  parteItem )
          commit('SET_LOADING', true, { root: true })            
          commit('SET_SUCCESS', true, { ropot: true })
          console.log('124 setParteRecogida',parteItem)


          } catch (error) {
            console.log('error-recogida',error)
            throw error
            console.log('error-recogida',error)
            commit('SET_ERROR', error, { root: true })
          } finally {
            console.log('0410 setParteRecogida FINALY');
            commit('SET_LOADING', false, { root: true })
          }
        
      },

      async setParteRecogidaOld({ commit, rootState, state }, parteEnviado) {
        try {
          // traigo parte 
          const parteGuardado = JSON.parse(state.parteRecogida)

          const config = JSON.parse(state.config).filter(item=> item.Ruta.id !== parteEnviado.Ruta.id) || []
          const rutaActual =JSON.parse(state.rutaActual)

          console.log('inicializo parteGuardado : ',parteGuardado,typeof(parteGuardado),
          'parteEnviado', parteEnviado,
          'configuracion', config,
          'rutaActual', rutaActual,
          'son iguales',rutaActual===config
          )

          const parteItem = parteGuardado.filter(item=> item.Explotacion.id !== parteEnviado.Explotacion.id) || []

          // si estoy no hago nada
          console.log('inicializo 22222 ',parteGuardado,typeof(parteGuardado),
          'parteItem',parteItem,          

          )
          parteItem.push(parteEnviado)

          commit('SET_PARTE_RECOGIDA',  parteItem )
          console.log('assigno parte guardado',parteItem,
          'parteGuardado',parteGuardado
          )


            
              

            commit('SET_LOADING', true, { root: true })
            

            
            commit('SET_SUCCESS', true, { root: true })


          } catch (error) {
            console.log('error-recogida',error)
            throw error
            console.log('error-recogida',error)
            //commit('SET_ERROR', error, { root: true })
          } finally {
            commit('SET_LOADING', false, { root: true })
          }
        




      },

      async setParteOtraRecogida({ commit, rootState, state }, parteEnviado) {
        try {
          // traigo parte 
          const parteGuardado = JSON.parse(state.parteOtraRecogida)


          console.log('OtraRecogidasssss 25',parteGuardado,typeof(parteGuardado),
          'parteEnviado', parteEnviado,
          'parteGuardado', parteGuardado.OtraRecogida,
          'parteEnviado', parteEnviado.OtraRecogida,

          
          )

          

          const parteItem = parteGuardado.filter(item=> item.OtraRecogida.id !== parteEnviado.OtraRecogida.id) 

          // si estoy no hago nada
          console.log('inicializo 22222 ',parteGuardado,typeof(parteGuardado),
          'parteItem',parteItem,
          

          )
          parteItem.push(parteEnviado)

          commit('SET_PARTE_OTRA_RECOGIDA',  parteItem )
          console.log('assigno parte guardado',parteItem,
          'parteGuardado',parteGuardado
          )


            
              

            commit('SET_LOADING', true, { root: true })
            

            
            commit('SET_SUCCESS', true, { root: true })


          } catch (error) {
            console.log('error-recogida',error)
            throw error
            console.log('error-recogida',error)
            //commit('SET_ERROR', error, { root: true })
          } finally {
            commit('SET_LOADING', false, { root: true })
          }
        




      },

      async deleteParteOtraRecogida({ commit, rootState, state }, parteEnviado) {
        try {
          // traigo parte 
          const parteGuardado = JSON.parse(state.parteOtraRecogida) 
          console.log('2407 parteEnviado',parteEnviado,
          'parteGuardado',parteGuardado);
          
          //.descarga,parteEnviado.otraRecogida.Descarga.id)

          console.log('2407 parteGuardado',parteGuardado)

          const parteItem = parteGuardado.filter(item=> item.OtraRecogida.id !== parteEnviado.OtraRecogida.OtraRecogida.id) || []

          console.log('2407 parteItem',parteItem)

          commit('SET_PARTE_OTRA_RECOGIDA',  parteItem )
          commit('SET_LOADING', true, { root: true })
          commit('SET_SUCCESS', true, { root: true })


          } catch (error) {
            console.log('error-DESCARGA',error)
            throw error
            console.log('error-recogida',error)
            //commit('SET_ERROR', error, { root: true })
          } finally {
            commit('SET_LOADING', false, { root: true })
          }
        
      },

      async setParteDescarga({ commit, rootState, state }, parteEnviado) {
        try {
          // traigo parte 
          const parteGuardado = JSON.parse(state.parteDescarga)
          let parteItem
          console.log('inicializo ',parteGuardado,'-item',parteEnviado)

          if(parteEnviado.Descarga.id){
               parteItem = parteGuardado.filter(item=> item.Descarga.id !== parteEnviado.Descarga.id) 
          }else{
              parteItem = parteGuardado
          }

          console.log('inicializo 222222',parteGuardado,'-item',parteItem)

          
          parteItem.push(parteEnviado)

          commit('SET_PARTE_DESCARGA',  parteItem )
          console.log('inicializo assigno parte guardado',
          'parteGuardado',parteGuardado,
          'parteItem', parteItem,
          )
  
          commit('SET_LOADING', true, { root: true })
                        
          commit('SET_SUCCESS', true, { root: true })


          } catch (error) {
            console.log('error-recogida',error)
            throw error
            console.log('error-recogida',error)
            //commit('SET_ERROR', error, { root: true })
          } finally {
            commit('SET_LOADING', false, { root: true })
          }
        




      },

      async deleteParteDescarga({ commit, rootState, state }, parteEnviado) {
        try {
          // traigo parte 
          const parteGuardado = JSON.parse(state.parteDescarga) 
          console.log('2407 parteEnviado',parteEnviado,parteEnviado.descarga,parteEnviado.descarga.Descarga.id)

          console.log('2407 parteGuardado',parteGuardado)

          const parteItem = parteGuardado.filter(item=> item.Descarga.id !== parteEnviado.descarga.Descarga.id) || []

          console.log('2407 parteItem',parteItem)


          commit('SET_PARTE_DESCARGA',  parteItem )
          commit('SET_LOADING', true, { root: true })
          commit('SET_SUCCESS', true, { root: true })


          } catch (error) {
            console.log('error-DESCARGA',error)
            throw error
            console.log('error-recogida',error)
            //commit('SET_ERROR', error, { root: true })
          } finally {
            commit('SET_LOADING', false, { root: true })
          }
        




      },
     
      async updateConfig({ commit, rootState, state }, ruta) {
        // recibo form ... modificado..
        // filter de config sin form .. 
        // push config and SETCONFIG...

        try {
          // traigo config 
          const config = JSON.parse(state.config)
          console.log('inicializo UPDATE',config,typeof(config),
          'ruta', ruta)
          const configItem = config.filter(item=> item.Ruta.id !== ruta.Ruta.id) || []
          
          // si estoy no hago nada
          console.log('inicializo UPDATE222222',config,typeof(config),
          'ruta',ruta,
          'configItem',configItem,
          

          )

              configItem.push(ruta)

              commit('SET_CONFIG',  configItem )
              commit('SET_RUTA_ACTUAL',  ruta )

              console.log('set_config assigno UPDATE',config,configItem,
              'ruta',ruta
              )
              
          
        } catch (error) {
          console.log('error-recogida',error)
          throw error
          console.log('error-recogida',error)
          //commit('SET_ERROR', error, { root: true })
        } finally {
           commit('SET_LOADING', false, { root: true })
        }

      },
        
      
      async setConfig4({ commit, rootState, state }, ruta) {
        try {

          // traigo config 
          const config = JSON.parse(state.config)
          const configItem = config.findIndex(item=> item.Ruta.id === ruta.Ruta.id)


          if(configItem !== -1){
              config[configItem] = ruta;
          } else {
              config.push(ruta);

          }
            
          console.log('setConfig 1209',configItem, config);

          commit('SET_LOADING', true, { root: true })
            
          commit('SET_SUCCESS', true, { root: true })


          } catch (error) {
            console.log('error-recogida',error)
            throw error
            console.log('error-recogida',error)
            //commit('SET_ERROR', error, { root: true })
          } finally {
            commit('SET_LOADING', false, { root: true })
          }
        




      },

    



      async setConfig({ commit, rootState, state }, ruta) {
        try {

          console.log('setConfig',ruta);
            // busco una ruta bajada de este conductor 
            if (ruta.length==0){

             // commit('SET_CONFIG',  [] ) // INICALIZO 
             //quitado , deberia inicializar solo si cambia de usuario ???? 
            }else{

              const config = JSON.parse(state.config) 
              //commit('SET_CONFIG',  [] ) // INICALIZO  
              const configItem = config.find(item=> item.Ruta.id === ruta.Ruta.id) || []

              if (configItem.length === 0 ){
                config.push(ruta)
                commit('SET_CONFIG',  config )

              } 
              
              commit('SET_RUTA_ACTUAL',  ruta )
          
              commit('SET_LOADING', true, { root: true })
                
              commit('SET_SUCCESS', true, { root: true })


            }

          } catch (error) {
            console.log('error-recogida',error)
            throw error
            console.log('error-recogida',error)
            //commit('SET_ERROR', error, { root: true })
          } finally {
            commit('SET_LOADING', false, { root: true })
          }
        




      },

      async setConfigOLD({ commit, rootState, state }, ruta) {
        try {

          // traigo config 
          const config = JSON.parse(state.config)
         console.log('set_config 1 ',config,typeof(config),'ruta', ruta)

         const configItem = config.find(item=> item.Ruta.id === ruta.Ruta.id) || []
         const authUser = rootState.auth.user.conductor;
          console.log('set_config rootState 1209',authUser,rootState.auth.user.conductor,configItem,config,ruta)

          // si estoy no hago nada
         /*  console.log('setConfig 2',config,typeof(config),
          'ruta',ruta,
          'configItem',configItem,
          configItem.length === 0,
          configItem.length 
          

          )
 */

          if (configItem.length === 0 ){

            ruta.conductor = authUser
            console.log('set_config ruta 1209',ruta)

              config.push(ruta)
              

//              commit('SET_CONFIG',  config )
//             commit('SET_RUTA_ACTUAL',  ruta )
            /*  commit('SET_CONFIG',  configItem )
              commit('SET_RUTA_ACTUAL',  configItem ) */
              console.log('set_config 1209',configItem,
              'ruta',ruta
              )
              
          }else{

                        configItem.conductor = authUser


            console.log('set_config else 1209',configItem)

          }

          commit('SET_CONFIG',  config )
          console.log('set_config config',config, configItem)

          commit('SET_RUTA_ACTUAL',  ruta )
       
          // si no estoy añado a config ..
              //commit('SET_CONFIG',  config )
            
            
              //commit('SET_RUTA_ACTUAL',  ruta )
             //  commit('SET_RUTA_ACTUAL',  configItem )

            commit('SET_LOADING', true, { root: true })
            

            
            commit('SET_SUCCESS', true, { root: true })

            console.log('0811 set_config', config,configItem)


          } catch (error) {
            console.log('error-recogida',error)
            throw error
            console.log('error-recogida',error)
            //commit('SET_ERROR', error, { root: true })
          } finally {
            commit('SET_LOADING', false, { root: true })
          }
        




      },

      async setConfig2({ commit, rootState, state }, collect) {



          console.log ('config -commit',commit, 
          'rootstate',rootState, 
          'state',state,
          'data',collect,collect.Ruta.id
          ) 
           try {
            // traigo config 
            // si estoy 
              commit('SET_LOADING', true, { root: true })
             /*  if (rootState.online) {
                await SyncService.upload(collect)
              } else { */
                const config = JSON.parse(state.config)
                console.log('try-config-1',config,typeof(config))

                if(config.length>0){
                 /*   const configItem = config.filter(item=> {
                    console.log('try-config-2', item.Ruta.id !== collect.Ruta.id)
                    //,collect.ruta.id,item.id === collect.Ruta.id)

                    item.Ruta.id !== collect.Ruta.id
                  return item
                  }  
                  ) */
                  const configItem = config.filter(item=> item.Ruta.id !== collect.Ruta.id)
                  
                 // const configItem2 = config.find(item=> item.Ruta.id === collect.Ruta.id)
                  
                  console.log('try-config-2',configItem[0],configItem.length>0,config.filter(item=> item.Ruta.id !== collect.Ruta.id))
                  //console.log('try-config-2',configItem2,configItem2.length>0,config.find(item=> item.Ruta.id === collect.Ruta.id))


                 // if(configItem.length>0) {
                    console.log('try-config-2-1',configItem)
                    configItem.push(collect)

                   // config.push(configItem[0])
                    console.log('try-config-2-2',configItem)

                    //commit('SET_CONF',  configItem )
                    console.log('try-config-2-3',config)
                    commit('SET_CONFIG',  configItem )

                    console.log('set_config try-config-2-4',config)

                 // }
                
                    
                   console.log('try-config-3',configItem)
                   // busco y  
                

                }else{
                  console.log('try-config-3-1',collect,config)

                  config.push(collect)
                  //commit('SET_CONF',  {config} )
                  commit('SET_CONFIG',  config )

                }

                // const confRuta = state.getters.getConfigId(collect.Ruta.id)
                 console.log('try-config-4',config,typeof(config))
                // console.log('try-config',confRuta,typeof(confRuta))

              /*  console.log('try-data',data,typeof(data))
                console.log('try-assing',Object.assign(data,config)) */
                

                //config.push(collect)
                console.log('try-config-5',config,typeof(config))

                //commit('SET_CONF', { config })
              //}
              commit('SET_RUTA_ACTUAL',  collect )
              commit('SET_SUCCESS', true, { root: true })
            } catch (error) {
              console.log('error-recogida',error)
              throw error
              console.log('error-recogida',error)
              //commit('SET_ERROR', error, { root: true })
            } finally {
              commit('SET_LOADING', false, { root: true })
            }
      } 



    }


}