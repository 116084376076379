import { ref, provide } from 'vue'

export default function useSnackbar() {
    const snackbar = ref({
      show: false,
      message: '',
      color: '',
      variant: '',
      timeout: 3000,
    });

    const createSnackbar = (message, color, variant, timeout) => {
      snackbar.value = {
        show: true,
        message,
        color,
        variant,
        timeout,
      }
    }

    const createSuccessSnackbar = message => {
        createSnackbar(message, 'success', 'tonal', 3000)
    }

    const createErrorSnackbar = message => {
        createSnackbar(message, 'error', 'tonal', 3000)
    }

    provide('snackbar', snackbar)

    return {
      createSuccessSnackbar, createErrorSnackbar,
    }
}
