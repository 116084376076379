<template>
  <v-overlay
    :model-value="$store.state.loading"
    class="align-center justify-center"
  >
    <v-progress-circular
      color="amber"
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-overlay>
</template>
