<script setup>
import { provide } from 'vue'
import NetworkStatus from '@/components/AppNetworkStatus.vue'
import useSnackbar from '@/composables/useSnackbar'
import SnackNotifier from '@/components/AppSnackNotifier.vue'

const { createSuccessSnackbar, createErrorSnackbar } = useSnackbar()

provide('createSuccessSnackbar', createSuccessSnackbar)
provide('createErrorSnackbar', createErrorSnackbar)

const appName = process.env.VUE_APP_NAME
</script>

<template>
  <v-app>
    <v-app-bar
      color="primary"
      :title="appName"
    >

      <v-spacer></v-spacer>

      <network-status />
    </v-app-bar>

    <v-main>
      <v-layout>
        <v-container fluid fill-height>
          <router-view />
        </v-container>

        <snack-notifier />
      </v-layout>
    </v-main>
  </v-app>
</template>
