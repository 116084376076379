<script setup>
import { inject } from 'vue'

let snackbar = inject('snackbar')
</script>

<template>
  <v-snackbar
    v-model="snackbar.show"
    :color="snackbar.color"
    :timeout="snackbar.timeout"
  >
    {{ snackbar.message }}
  </v-snackbar>
</template>

