import { createLogger, createStore } from 'vuex'
import createPersistedState from 'vuex-persist'
import localforage from 'localforage'

import auth from '@/store/modules/auth'
import sync from '@/store/modules/sync'
import recogida from '@/store/modules/recogida'
import resumen from '@/store/modules/resumen'

localforage.config({
  name: 'gecotrail',
  storeName: 'gecotrail',
  description: 'Gecotrail App',
})

const persist = new createPersistedState({
  key: 'gecotrail',
  storage: localforage,
  asyncStorage: true,
  paths: ['auth'],
});

export default createStore({
  modules: {
    auth,
    sync,
    recogida,
    resumen,
  },
  state: {
    loading: false,
    online: true,
    success: null,
    error: null,
  },
  getters: {
    isOnline: state => state.online
  
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_ONLINE(state, online) {
      state.online = online
    },
    SET_SUCCESS(state, success) {
      state.success = success
    },
    SET_ERROR(state, error) {
      state.error = error
    }
  },
  plugins: [persist.plugin,createLogger],
});
